import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <AiIcons.AiFillDashboard />,
    cName: 'nav-text text-light'
  },
  {
    title: 'Invoices',
    path: '/invoices',
    icon: <IoIcons.IoMdFolder />,
    cName: 'nav-text'
  },
  {
    title: 'Export Invoices',
    path: '/exportInvoices',
    icon: <IoIcons.IoMdArchive />,
    cName: 'nav-text'
  },
  {
    title: 'Job History',
    path: '/jobHistories',
    icon: <IoIcons.IoIosArchive />,
    cName: 'nav-text'
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: <IoIcons.IoIosSettings />,
    cName: 'nav-text'
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: <IoIcons.IoMdExit style={{ transform: 'rotate(180deg)' }} />,
    cName: 'nav-text'
  }
];