import React, { useState, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useParams, useLocation  } from 'react-router-dom';
import localImage from '../images/background.jpg';
import axiosInstance from '../components/axio';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
  },
  image: {
    width: 150,
    height: 75,
    marginBottom: 10,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    margin: 10,
  },
  alignCenter: {
    textAlign: 'center',
  },
  content: {
    fontSize: 9,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: "auto",
    fontSize: 20,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableCol: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  tableColSpan: {
    width: '66%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW50: {
    width: '49.5%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW99: {
    width: '99%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW30: {
    width: '30%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW13: {
    width: '13%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableColHeader30: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader13: {
    width: '13%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader20: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader99: {
    width: '99%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader15: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableW20: {
    width: '20%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW15: {
    width: '15%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW26: {
    width: '26%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW33: {
    width: '33%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW66: {
    width: '66%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW60: {
    width: '60%',
  },
  
  tableColHeader26: {
    width: '26%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  footer: {
    marginTop: 'auto', // Pushes the footer to the bottom
    padding: 10,
    fontSize: 9,
    textAlign: 'center',
    borderTopWidth: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid'
  }

});

const PrintMultipleInvoices = () => {
  
  const location = useLocation();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const [businessDetails, setBusinessDetails] = useState({
    basics: {
      businessName: '',
      contactName: '',
      contactNumber: '',
      contactAddress: '',
      contactEmail: '',
      userId: '',
    },
    invoiceTemplateDetails: {
      companyName: '',
      contactNumber: '',
      emailAddress: '',
      regNumber: '',
      vatNumber: '',
      companyAddress: '',
    },
    integrations: {
      backMarket: '',
      refurbed: '',
    }
  });

  const currencySymbols = {
    EUR: '€',
    GBP: '£',
    USD: '$',
    SEK: 'SEK',
    DKK: 'DKK'
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromDate = queryParams.get('fromDate');
    const toDate = queryParams.get('toDate');
    const platformId = queryParams.get('platformId');
    const taxOptionId = queryParams.get('taxOptionId');
    const userId = queryParams.get('userId');

    const fetchInvoices = async () => {
      try {
        const response = await axiosInstance(
          `/invoices/export?fromDate=${fromDate}&toDate=${toDate}&platformId=${platformId}&taxOptionId=${taxOptionId}&userId=${userId}`
        );
        if (response.status!==200) {
          throw new Error('Failed to fetch invoices');
        }
        const data = await response.data;
        setInvoices(data.invoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchInvoices();
    fetchBusinessDetails(userId);
  }, [location.search]);

  const fetchBusinessDetails = async (userId) => {
    try {
      const response = await axiosInstance.get(`/businesses/${userId}`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch business data');
      }
      const businessData = await response.data;
      setBusinessDetails({
        invoiceTemplateDetails: {
          companyName: businessData.invoiceTemplateDetails.companyName,
          contactNumber: businessData.invoiceTemplateDetails.contactNumber,
          emailAddress: businessData.invoiceTemplateDetails.emailAddress,
          regNumber: businessData.invoiceTemplateDetails.regNumber,
          vatNumber: businessData.invoiceTemplateDetails.vatNumber,
          companyAddress: businessData.invoiceTemplateDetails.companyAddress,
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formatDate = (utcDate) => {
    const date = new Date(utcDate); // Convert UTC to local date
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based) and pad with 0 if needed
    const year = date.getFullYear(); // Get the year
  
    return `${day}-${month}-${year}`; // Format to dd-mm-yyyy
  };

  const [imageUri] = useState(localImage);
  if (loading) {
    return <div>Loading invoices...</div>;
  }

  if (invoices.length === 0) {
    return <div>No invoices found for the selected criteria.</div>;
  }

  return (
    <PDFViewer style={{ width: '100%', height: '99vh' }}>
      <Document>
      {invoices.map((invoice, index) => (
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>{invoice.financials.invoiceName ? invoice.financials.invoiceName.toUpperCase() : "INVOICE"}</Text>
          
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}><Text style={styles.tableCell}>Invoice No: {invoice.basics.invoiceNo}</Text></View>
              <View style={styles.tableColHeader}><Text style={styles.tableCell}>Invoice Date: {formatDate(invoice.basics.invoiceDateTime)}</Text></View>
              <View style={styles.tableColHeader}><Text style={styles.tableCell}>Order Ref: {invoice.basics.orderRef}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW50}><Text style={styles.tableCell}>Bill to: {invoice.customer.name}</Text></View>
              <View style={styles.tableW50}><Text style={styles.tableCell}>Contact No: {invoice.customer.phone}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW99}><Text style={styles.tableCell}>Address: {invoice.customer.billingAddress}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Company Name: {invoice.customer.companyName}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Company Reg: {invoice.customer.companyRegNumber}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>VAT No: {invoice.customer.vatNumber}</Text></View>
            </View>
          </View>

          <View style={styles.table}>
            
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader30}><Text style={styles.tableCell}>Item </Text></View>
              <View style={styles.tableColHeader30}><Text style={styles.tableCell}>Description </Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>Qty </Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>Unit Price </Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>Total Price </Text></View>
            </View>
            {invoice.products.map((product, productIndex) => (
                <View style={styles.tableRow} key={productIndex}>
                  <View style={styles.tableW30}><Text style={styles.tableCell}>{product.name}</Text></View>
                  <View style={styles.tableW30}><Text style={styles.tableCell}>{product.description}</Text></View>
                  <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{product.quantity}</Text></View>
                  <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySymbols[invoice.basics.currency]}{product.unitPrice.toFixed(2)}</Text></View>
                  <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySymbols[invoice.basics.currency]}{(product.unitPrice * product.quantity).toFixed(2)}</Text></View>
                </View>
              ))}
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Discount</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}>
                <Text style={styles.tableCell}>
                  {currencySymbols[invoice.basics.currency]}{!isNaN(invoice.financials.discount) && invoice.financials.discount !== null ? parseFloat(invoice.financials.discount).toFixed(2) : '0.00'}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Sub Total</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySymbols[invoice.basics.currency]}{parseFloat(invoice.financials.totalAmount-invoice.financials.taxAmount+invoice.financials.discount).toFixed(2)}</Text></View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Tax ({invoice.financials.taxPercentage}%)</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}>
                <Text style={styles.tableCell}>
                  {currencySymbols[invoice.basics.currency]}{!isNaN(invoice.financials.taxAmount) && invoice.financials.taxAmount !== null ? parseFloat(invoice.financials.taxAmount).toFixed(2) : '0.00'}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Shipping Cost</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}>
                <Text style={styles.tableCell}>
                  {currencySymbols[invoice.basics.currency]}{!isNaN(invoice.shippingDetails.cost) && invoice.shippingDetails.cost !== null ? parseFloat(invoice.shippingDetails.cost).toFixed(2) : '0.00'}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Total Amount</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}>
                <Text style={styles.tableCell}>
                  {currencySymbols[invoice.basics.currency]}{!isNaN(invoice.financials.totalAmount) && invoice.financials.totalAmount !== null ? parseFloat(invoice.financials.totalAmount).toFixed(2) : '0.00'}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Paid Amount</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}>
                <Text style={styles.tableCell}>
                  {currencySymbols[invoice.basics.currency]}{!isNaN(invoice.financials.paidAmount) && invoice.financials.paidAmount !== null ? parseFloat(invoice.financials.paidAmount).toFixed(2) : '0.00'}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableColHeader26, ...styles.alignCenter}}><Text style={styles.tableCell}>Amount Due</Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}>
                <Text style={styles.tableCell}>
                  {currencySymbols[invoice.basics.currency]}{!isNaN(invoice.financials.balance) && invoice.financials.balance !== null ? parseFloat(invoice.financials.balance).toFixed(2) : '0.00'}
                </Text>
                </View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={{...styles.tableColHeader99, ...styles.alignCenter}}><Text style={styles.tableCell}>Notes</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={{...styles.tableW33, ...styles.alignCenter}}><Text style={styles.tableCell}>{invoice.shippingDetails.shipper}</Text></View>
              <View style={styles.tableW66}><Text style={styles.tableCell}>{invoice.shippingDetails.trackingNumber}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={{...styles.tableW33, ...styles.alignCenter}}><Text style={styles.tableCell}>Delivery address</Text></View>
              <View style={styles.tableW66}><Text style={styles.tableCell}>{invoice.shippingDetails.address}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={{...styles.tableW33, ...styles.alignCenter}}><Text style={styles.tableCell}>Invoice Note</Text></View>
              <View style={styles.tableW66}><Text style={styles.tableCell}>{invoice.financials.invoiceNote}</Text></View>
            </View>
          </View>

          <View style={styles.footer}>
            <Text style={styles.content}>{businessDetails.invoiceTemplateDetails.companyName}</Text>
            <Text style={styles.content}>{businessDetails.invoiceTemplateDetails.companyAddress}</Text>
            <Text style={styles.content}>Company Reg. No: {businessDetails.invoiceTemplateDetails.regNumber} - VAT No: {businessDetails.invoiceTemplateDetails.vatNumber}</Text>
            <Text style={styles.content}>{businessDetails.invoiceTemplateDetails.emailAddress}</Text>
          </View>
        </Page>
        ))}
      </Document>
    </PDFViewer>
  );
};

export default PrintMultipleInvoices;