import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css';
import '../css/calculateSavings.css';
import '../css/services.css'
import 'bootstrap';
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';

export default function CalculateSavings() {
  const [menuHeight, setMenuHeight] = useState(0);
  const [numberOfOrders, setNumberOfOrders] = useState('');
  const [averageProductAmount, setAverageProductAmount] = useState('');
  const [feePercentage, setFeePercentage] = useState('');
  const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  // Detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    function formatNumber(value) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    }

    function formatIntNumber(value) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    }

    const formattedOrders = formatIntNumber(numberOfOrders || 0);
    const formattedAmount = formatNumber(averageProductAmount || 0);
    const calculatedCurrentFee = numberOfOrders * averageProductAmount * (feePercentage / 100) || 0;
    const calculatedFlexmerceCharges = numberOfOrders * 0.2 || 0;

    const predictedFees = [];
    for (let i = 1; i <= 5; i++) {
      const predictedFeePercentage = feePercentage - i * 0.1;
      const predictedFee = numberOfOrders * averageProductAmount * (predictedFeePercentage / 100) || 0;
      const calculateFlexmerceCharges = (numberOfOrders) => {
        let rate = 0; // Default rate when `numberOfOrders` is 0
        
        if (numberOfOrders > 0 && numberOfOrders <= 999) {
          rate = 0.20;
        } else if (numberOfOrders >= 1000 && numberOfOrders <= 2000) {
          rate = 0.17;
        } else if (numberOfOrders > 2000) {
          rate = 0.15;
        }
        
        return numberOfOrders * rate;
      };


      const scenarioFlexmerceCharges = calculateFlexmerceCharges(numberOfOrders);
      const predictedSaving = calculatedCurrentFee - predictedFee - scenarioFlexmerceCharges;

      predictedFees.push({
        percentage: formatNumber(predictedFeePercentage) + '%',
        fee: formatNumber(predictedFee),
        saving: (predictedSaving >= 0 ? '+' : '') + formatNumber(predictedSaving),
        scenarioFlexmerce: formatNumber(scenarioFlexmerceCharges),
      });
    
    }

    // Structure data for a table or cards
    const tableData = [
      /*['Number of orders', formattedOrders, formattedOrders, formattedOrders, formattedOrders, formattedOrders, formattedOrders],
      ['Average amount', formattedAmount, formattedAmount, formattedAmount, formattedAmount, formattedAmount, formattedAmount],*/
      ['Fee percentage', formatNumber(feePercentage) + '%', ...predictedFees.map((item) => item.percentage)],
      ['Fee amount', formatNumber(calculatedCurrentFee), ...predictedFees.map((item) => item.fee)],
      ['Fee amount Difference', '-', ...predictedFees.map((item) => '-' + formatNumber(calculatedCurrentFee - parseFloat(item.fee.replace(',', ''))))],
      ['Flexmerce charges', '-', ...predictedFees.map((item) => item.scenarioFlexmerce)],
      ['Total Savings', '-', ...predictedFees.map((item) => item.saving)],
    ];

    setData(tableData);
  }, [numberOfOrders, averageProductAmount, feePercentage]);

  const renderTable = () => (
    <div className="calculate-saving-table table-container calculate-saving-form mt-3">
      <table className="responsive-table">
        <thead>
          <tr className='calculate-saving-table-tr'>
            <th></th>
            <th>Currently</th>
            <th>Initial Impact<br /> <span style={{ fontSize: '0.7em' }}>(straightforward to achieve)</span></th>
            <th>Enhanced Returns<br /> <span style={{ fontSize: '0.7em' }}>(promising improvement)</span></th>
            <th style={{ backgroundColor: '#ff8a00', color: '#fff', fontSize: '1.2em' }}>Optimal Balance<br /> <span style={{ fontSize: '0.7em' }}>(the popular result)</span> </th>
            <th>Advanced Savings<br /> <span style={{ fontSize: '0.7em' }}>(aiming at higher returns)</span></th>
            <th>Peak Efficiency<br /> <span style={{ fontSize: '0.7em' }}>(top-end potential)</span></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{
                color: rowIndex === data.length - 1 ? '#fff' : '',
                fontSize: rowIndex === data.length - 1 ? '1.1rem' : '1rem',
                fontWeight: rowIndex === data.length - 1 ? 'bold' : 'normal',
              }}
              className='calculate-saving-table-tr'
            >
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    backgroundColor: cellIndex === 4 ? '#ff8a00' : '',
                    color: cellIndex === 0 ? '#ff8a00' : '#fff',
                    fontSize: (cellIndex === 0 || cellIndex === 4 || rowIndex === data.length - 1) ? '1.1rem' : '1rem',
                    fontWeight: cellIndex === 0 || rowIndex === 0 || rowIndex === data.length - 1 || cellIndex === 4 ? 'bold' : 'normal',
                  }}
                  className='calculate-saving-table-tr'
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderCards = () => {
    // Extract each scenario's data, skipping the first column header row (row[0])
    const scenarioData = data[0].slice(1).map((_, index) => {
      return data.map(row => row[index + 1]);
    });
  
    // Reorder the scenarios: Currently -> Scenario 3 -> others
    const reorderedScenarioData = [
      scenarioData[0],           // "Currently"
      scenarioData[3],           // "Scenario 3"
      ...scenarioData.slice(1, 3), // Rest of scenarios
      ...scenarioData.slice(4),   // remaining
    ];
  
    // Custom titles for each card
    const titles = ["Currently",
       "Optimal Balance (the popular result)",
        "Initial Impact (straightforward to achieve)",
         "Enhanced Returns (promising improvement)",
          "Advanced Savings (aiming at higher returns)",
           "Peak Efficiency (top-end potential)"];
  
    return (
      <div className="card-container mt-3">
        {reorderedScenarioData.map((scenario, index) => (
          <div 
            className="metric-card" 
            key={index}
            style={{
              backgroundColor: titles[index] === "Optimal Balance (the popular result)" ? "#ff8a00" : "",
              color: titles[index] === "Optimal Balance (the popular result)" ? "transparent" : "", 
            }}
          >
            <h4>{titles[index]}</h4>
            <div className="metric-values">
              {data.map((row, rowIndex) => (
                <div key={rowIndex} className="card-metric">
                  <h5>{row[0]}</h5>
                  <p>{scenario[rowIndex]}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      <HomepageMenu />
      <Helmet>
        <title>Calculate Savings</title>
        <meta name="description" content="Use Flexmerce's Calculate Savings tool to estimate your potential cost savings by automating invoicing and customer support. Discover how Flexmerce can help reduce marketplace fees on platforms like Backmarket and Refurbed, while boosting customer satisfaction and streamlining operations for online sellers." />
      </Helmet>
      <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem" style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}>
        <div className="container mb-5">
          <h1 className='first-title-container font-orange text-center mt-5'>Calculate Savings</h1>
          <p className='mt-2 pt-3 mr-3 ml-3 font-light home-services-p  text-center'>
            Let's calculate how much we can save you by setting your invoicing on Autopilot with Flexmerce.
          </p>
          <p className=' font-light home-services-p text-center'>
            Flexmerce is designed to help online sellers reduce marketplace fees on platforms like Backmarket and Refurbed by automating invoicing and customer support processes. By minimizing the time and effort required to address customer service tickets, Flexmerce enables sellers to maintain a higher level of customer satisfaction.
          </p>

          <div>
            <form className='calculate-saving-form mt-5'>
              <h3 className='font-light text-center mb-1'>Enter your monthly figures</h3>
              <hr className='mr-5 ml-5'></hr>
              <div className="row text-center">
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      value={numberOfOrders}
                      className="form-control p-4"
                      onChange={(e) => setNumberOfOrders(e.target.value)}
                      required
                      placeholder=""
                    />
                    <label className="floating-label">Number of orders</label>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      value={averageProductAmount}
                      step="0.01"
                      className="form-control p-4"
                      placeholder=""
                      onChange={(e) => setAverageProductAmount(e.target.value)}
                      required
                    />
                    <label className="floating-label">Average Product Amount</label>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      value={feePercentage}
                      step="0.01"
                      className="form-control p-4"
                      placeholder=""
                      onChange={(e) => setFeePercentage(e.target.value)}
                      required
                    />
                    <label className="floating-label">Marketplace Fee Percentage</label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {isMobile ? renderCards() : renderTable()}

          <p className='font-light home-services-p text-center mt-5'>
            Automatically delivering invoices not only improves customer experience but also showcases business professionalism and reliability, which can contribute positively to marketplace reputation. This enhanced brand reputation can lead to lower marketplace fee percentages over time, providing sellers with cost savings and a competitive edge.
          </p>

          <p className='font-light home-services-p text-center'>
            With Flexmerce, businesses can enjoy streamlined operations, reduced support load, and a stronger presence on marketplaces, all while keeping customers happy and satisfied.
          </p>

          <ContactSection />
        </div>
        <Footer />
      </div>
    </>
  );
}
