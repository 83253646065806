import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import '../css/sidebar.css';
import { IconContext } from 'react-icons';
import axiosInstance from '../components/axio';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const showSidebar = () => setSidebar(!sidebar);

  // Determine if the sidebar should be hidden based on the current route
  const isPrintInvoicePage = location.pathname.startsWith('/printInvoice/');
  const isPrintMultipleInvoicePage = location.pathname.startsWith('/PrintMultipleInvoices');
  const isLoginPage = location.pathname.startsWith('/login');
  const isContactPage = location.pathname.startsWith('/contact');
  const isRootPath = location.pathname === '' || location.pathname === '/' || location.pathname === '/home';

  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      {isAuthenticated && !isPrintInvoicePage && !isPrintMultipleInvoicePage
      && !isLoginPage
      && !isContactPage
      && !isRootPath && (
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <h4 className='text-light font-900 nav-bar-span mr-2'>{localStorage.getItem('businessName')}</h4>
        </div>
        
      )}
      {!isPrintInvoicePage && (
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => (
              <li
                key={index}
                className={`${item.cName} ${location.pathname === item.path ? 'active' : ''}`}
              >
                <Link to={item.path}>
                  {item.icon}
                  <span className='text-light sideBarSpan'>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </IconContext.Provider>
  );
}

export default Navbar;
