import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap'
import '../css/dashboard.css';
import CheckAuth from '../hooks/checkAuth';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';

export default function AddUpdateTaxOption() {
    CheckAuth();
    const navigate = useNavigate();
    const { taxOptionId } = useParams();
    const isEditMode = Boolean(taxOptionId);
    const submitBttonVal = isEditMode ? 'Edit option' : 'Create option';
    const PageTitleVat = isEditMode ? 'Edit tax option' : 'Add new tax option';
    const [loading, setLoading] = useState(false);
    const [taxOptionDetails, settaxOptionDetails] = useState({
        userId: localStorage.getItem('userId'),
        optionTitle: String,
        invoiceName: String,
        percentage: Number,
        invoiceNote: String,
        active: Number
    });

    const handleTaxOptionDetailsChange = (field, value) => {
        settaxOptionDetails(prevDetails => ({
            ...prevDetails,
            [field]: value
        }));
    };

    useEffect(() => {
        async function fetchTaxOption() {

            
            if (taxOptionId) {
                setLoading(true);
                try {
                    const response = await axiosInstance.get(`/taxOption/single/${taxOptionId}`);
                    if (response.status!==200) {
                        throw new Error('Failed to fetch Tax options');
                    }
                    const data = await response.data;
                    settaxOptionDetails(data);
                    setLoading(false);
                } catch (error) {
                    console.error('Error:', error);
                    setLoading(false);
                }
            }
        }
        fetchTaxOption();
    }, []);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const payload = {
            userId: localStorage.getItem('userId'),
            optionTitle: taxOptionDetails.optionTitle,
            invoiceName: taxOptionDetails.invoiceName,
            percentage: taxOptionDetails.percentage,
            invoiceNote: taxOptionDetails.invoiceNote,
            active: taxOptionDetails.active
        };
        const url = taxOptionId ? `/taxOption/edit/${taxOptionId}` : `/taxOption/new`;
    
        try {
            const response = taxOptionId ? await axiosInstance.put(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }) : await axiosInstance.post(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            await response.data;
            setLoading(false);
            navigate('/manageTaxOptions');
        } catch (error) {
            console.error('Failed to create/update TaxOptions:', error);
            setLoading(false);
            alert('Failed to create/update TaxOptions.');
        }
    };

    return (
        <div className='container mb-5'>
            <Helmet>
                <title>Manage Tax Option</title>
            </Helmet>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <h2 className='mt-3 mb-4 fw-bold'>{PageTitleVat}</h2>
            
            <div className='card border-radius-20'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-group m-1 p-1">
                                <input
                                    type="text"
                                    value={taxOptionDetails.optionTitle}
                                    onChange={e => handleTaxOptionDetailsChange('optionTitle', e.target.value)}
                                    placeholder=""
                                    className='form-control w-100'
                                    required
                                />
                                <label className="floating-label-register">Option title</label>
                            </div> 
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group m-1 p-1">
                                <input
                                    type="text"
                                    value={taxOptionDetails.invoiceName}
                                    onChange={e => handleTaxOptionDetailsChange('invoiceName', e.target.value)}
                                    placeholder=""
                                    className='form-control w-100'
                                    required
                                />
                                <label className="floating-label-register">Invoice name</label>
                            </div> 
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group m-1 p-1">
                                <input
                                    type="text"
                                    value={taxOptionDetails.percentage}
                                    onChange={e => handleTaxOptionDetailsChange('Percentage', e.target.value)}
                                    placeholder=""
                                    className='form-control w-100'
                                    required
                                />
                                <label className="floating-label-register">Percentage</label>
                            </div> 
                        </div>
                        <div className='col-sm-10'>
                            <div className="form-group m-1 p-1">
                                <textarea 
                                    className='form-control' 
                                    rows={3}
                                    name='invoiceNote'
                                    value={taxOptionDetails.invoiceNote}
                                    onChange={e => handleTaxOptionDetailsChange('invoiceNote', e.target.value)}
                                    placeholder=''
                                    required>
                                </textarea>
                                <label className="floating-label-register">Invoice note</label>
                            </div> 
                        </div>
                        <div className='col-sm-2'>
                            <div className="form-group m-1 p-1">
                                <select className='form-control'
                                    name='active'
                                    value={taxOptionDetails.active}
                                    onChange={(e) => handleTaxOptionDetailsChange('active', e.target.value)}
                                    required>
                                    <option value={''}> --- Please choose* --- </option>
                                    <option value={'1'}>Yes</option>
                                    <option value={'0'}>No</option>
                                </select>
                                <label className="floating-label-register">Active</label>
                            </div> 
                        </div>
                    </div>

                <div className='d-flex justify-content-center'>
                <input type="submit" className='btn btn-dark w-50 p-2 mt-5 mb-3 rounded-md bg-blue' value={submitBttonVal}/>
                </div>
                </form>
            </div>

        </div>
    )
}