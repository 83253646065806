import React from 'react';
export default function ContactSection() {
    return (
        <>
        <div className='contact-section text-center mt-5 p-5'>
            <h1 className='text-light font-orange'>Start Reducing Marketplace Fees Today</h1>
            <p className='text-light home-services-p'>Implement and automate invoices for your account</p>
            <div className='row'>
                <div className='col-sm-3'>
                    <a href='/registerBusiness'>
                        <button className='btn-services-calculate-savings'>
                            Start 7-days free trial
                        </button>
                    </a>
                </div>
                <div className='col-sm-3'>
                    <a href='/contact'>
                        <button className='btn-services-contact-2'>
                            Contact Us
                        </button>
                    </a>
                </div>
            </div>
        </div>
        </>
    );
}
