import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/verification.css';
import axiosInstance from '../components/axio';
import { useAuth } from '../context/AuthContext';

export default function EmailVerification() {
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    useEffect(() => {
        const userId = localStorage.getItem('userId');

        if (userId) {
            const checkEmailVerification = async () => {
                try {
                    const response = await axiosInstance.get(`/checkUserStatus/${userId}`, {
                        method: 'GET',
                    });

                    if (response.status === 200) {
                        setIsVerified(true);
                        localStorage.setItem('verified', true)
                        login('user');
                        setLoading(false);
                        navigate('/dashboard');
                    }
                } catch (error) {
                    console.error('Failed to check email verification:', error);
                }
            };

            // Check email verification every 5 seconds
            const intervalId = setInterval(checkEmailVerification, 5000);

            return () => clearInterval(intervalId);
        } else {
            navigate('/login')
        }
    }, [navigate]);

    return (
       <div className="verification-container d-flex flex-column align-items-center justify-content-center text-center">
            <h2 className="mb-3">Verify Your Email</h2>
            <p className="verification-message mb-4">
                We've sent a verification link to your email. Please click on the link to verify your account.
            </p>

            {loading && (
                <div className="loader-container mt-3">
                    <Circles
                        height="80"
                        width="80"
                        color="#ff8a00"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                    <p className="mt-3 waiting-text">Waiting for verification...</p>
                </div>
            )}

            {!loading && !isVerified && (
                <p className="text-warning mt-3">
                    It seems your email is not yet verified. Please check your inbox.
                </p>
            )}
        </div>
    );
}
