import React, { useState } from 'react';
import '../css/homepageMenu.css'; // Import the navbar CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function HomepageMenu() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className='menu-container'>
        <div className={`navbar glass-effect ${menuOpen ? 'menu-open' : ''}`}>
            <a href="/" className="logo-link"><div className="logo">FLEXMERCE</div></a>
            <div className="menu-icon" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <ul className={`menu-items ${menuOpen ? 'open' : ''}`}>
                <a href="/"><li>Home</li></a>
                <a href="/services"><li>Services</li></a>
                <a href="/pricing"><li>Pricing</li></a>
                <a href="/appointment"><li>Book a Demo</li></a>
                <a href="/contact"><li>Contact</li></a>
                <a href="/registerbusiness"><li>Sign up</li></a>
                <a href="/calculateSavings"><li className="btn-demo-homepage">Calculate Savings</li></a>
                <a href="/login"><li className="btn-login-homepage">Login</li></a>
                
            </ul>
        </div>
        </div>
    );
}
