import React, { useState } from 'react';
import '../css/footer.css';
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'
import rklogo from '../images/rk-logo.png'
import fnlogo from '../images/fn-logo.png'
import cdlogo from '../images/cd-logo.png'
import sflogo from '../images/sf-logo.png'

import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter} from 'react-icons/fa';
export default function Footer() {
    const images = [bmlogo, rflogo, rklogo, fnlogo, cdlogo, sflogo];
    return (
        <>
        <div className='footer pt-5 font-orange'>
            <hr></hr>
            <div className='row mt-5 mb-5 d-flex align-items-stretch'>
                <div className='col-sm-3'>
                    <h2 className='font-orange mt-4'>FLEXMERCE</h2>
                    <h6 className='text-light p-3 footer-subtitle'>
                        Your comprehensive portal for flexible commerce solutions. Proud Part of Topweb Limited (Reg. 12959957)
                        </h6>
                </div>
                <div className='col-sm-3'>
                    <ul className='footer-ul'>
                        <li><a href='/' className='underline-orange-thin'>Home</a></li>
                        <li><a href='/services' className='underline-orange-thin'>Services</a></li>
                        <li><a href='/appointment' className='underline-orange-thin'>Book a Demo</a></li>
                        <li><a href='/pricing' className='underline-orange-thin'>Pricing</a></li>
                    </ul>
                </div>
                <div className='col-sm-3'>
                    <ul className='footer-ul footer-ul-2'>
                        <li><a href='/calculatesavings' className='underline-orange-thin'>Calculate Savings</a></li>
                        <li><a href='/contact' className='underline-orange-thin'>Contact Us</a></li>
                        <li><a href='/blogs' className='underline-orange-thin'>Blogs</a></li>
                        <li><a href='/registerbusiness' className='underline-orange-thin'>Sign up</a></li>
                    </ul>
                </div>
                <div className='col-sm-3'>
                    <ul className='footer-ul'>
                        <li><a href='/' className='underline-orange-thin'>help@flexmerce.com</a></li>
                        <li><a href='/services' className='underline-orange-thin'>+44 (0) 333 789 0702</a></li>
                        <li><a>Canary Wharf | London</a></li>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF size={30} color="#fff" />
                            </a>
                            <a href="https://x.com/flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaTwitter size={30} color="#fff" />
                            </a>
                            <a href="https://www.instagram.com/flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} color="#fff" />
                            </a>
                            <a href="https://www.tiktok.com/@flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaTiktok size={30} color="#fff" />
                            </a>
                        </div>
                    </ul>
                </div>
            </div>
            <div className="w-100 relative z-10 d-flex align-content-center">
                <div className="absolute"></div>
                <div className="logo-row relative flex justify-between items-center max-w-4xl mx-auto px-4 py-8 pr-5 pl-5 pb-2  footerContainer">
                {images.map((image, index) => (
                    <div key={index} className="w-20 h-20 flex items-center justify-center bg-white bg-opacity-10 rounded-full p-2 platformIcon">
                    <div className="relative w-16 h-16">
                        <img src={image} alt={`Logo ${index + 1}`} className="object-contain w-full h-full" loading='lazy' />
                    </div>
                    </div>
                ))}
                </div>
            </div>
            <div className="w-100 relative z-10 d-flex align-content-center footer-copyright">
                <div className="absolute"></div>
                <div className=" relative flex justify-between items-center max-w-4xl mx-auto pb-3 footerContainer">
                <a href="/terms" className='font-orange text-legal'>Terms of use</a>
                <a href="/privacy" className='font-orange ml-5 text-legal'>Privacy policy</a>
                </div>
            </div>
            <div className="w-100 relative z-10 d-flex align-content-center footer-copyright">
                <div className="absolute"></div>
                <div className=" relative flex justify-between items-center max-w-4xl mx-auto pb-3 footerContainer">
                <span>{new Date().getFullYear()} Flexmerce | All rights reserved</span>
                </div>
            </div>
        </div>
        </>
    );
}
