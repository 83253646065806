import React, { useState, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useParams  } from 'react-router-dom';  // Correctly import useNavigate
import localImage from '../images/background.jpg'; // Ensure you have a local image for fallback
import axiosInstance from '../components/axio';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
  },
  image: {
    width: 150,
    height: 75,
    marginBottom: 10,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    margin: 10,
    maginTop: '60px !important',
  },
  alignCenter: {
    textAlign: 'center',
  },
  content: {
    fontSize: 9,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: "auto",
    fontSize: 20,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableCol: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  tableColSpan: {
    width: '66%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW50: {
    width: '49.5%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW99: {
    width: '99%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW30: {
    width: '30%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW13: {
    width: '13%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableColHeader30: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader13: {
    width: '13%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader20: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader99: {
    width: '99%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableColHeader15: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  tableW20: {
    width: '20%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW15: {
    width: '15%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW26: {
    width: '26%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW33: {
    width: '33%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW66: {
    width: '66%', // This column will span two columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableW60: {
    width: '60%',
  },
  
  tableColHeader26: {
    width: '26%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    backgroundColor: '#1b2033',  // Sets the background color to black
    color: '#FFFFFF',  
  },
  footer: {
    marginTop: 'auto', // Pushes the footer to the bottom
    padding: 10,
    fontSize: 9,
    textAlign: 'center',
    borderTopWidth: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid'
  }

});


const PrintInvoice = () => {
  
  const { invoiceId } = useParams(); 

  const [rows, setRows] = useState([{
    product: '',
    description: '',
    quantity: '',
    unitPrice: '',
    totalPrice: ''
  }]);

  const [invoiceDetails, setInvoiceDetails] = useState({
    shippingCost: '',
    discount: '',
    subTotal: '',
    paidAmount: '',
    balance: '',
    shippingAddress: '',
    shipper: '',
    trackingNumber: '',
    orderRef: '',
    invoiceDate: '',
    currency: '',
    customerName: '',
    companyName: '',
    phoneNumber: '',
    billingAddress: '',
    invoiceNo: '',
    totalAmount: '',
    companyRegNumber: '',
    taxAmount: '',
    taxPercentage: '',
    invoiceName: '',
    invoiceNote: ''
  });

  const [businessDetails, setBusinessDetails] = useState({
    basics: {
      businessName: '',
      contactName: '',
      contactNumber: '',
      contactAddress: '',
      contactEmail: '',
      userId: '',
    },
    invoiceTemplateDetails: {
      companyName: '',
      contactNumber: '',
      emailAddress: '',
      regNumber: '',
      vatNumber: '',
      companyAddress: '',
    },
    integrations: {
      backMarket: '',
      refurbed: '',
    }
  });

  const currencySymbols = {
    EUR: '€',
    GBP: '£',
    USD: '$',
    SEK: 'SEK',
    DKK: 'DKK'
  };

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (invoiceId) {
        try {
          const endpoint = invoiceId.length < 15
          ? `/invoices/getSingleInvoice/${invoiceId}` 
          : `/invoices/getSingleInvoiceForCustomer/${invoiceId}`;

          const response = await axiosInstance.get(endpoint);
          if (response.status!==200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.data;
          const invoiceData = data.invoice;

          const invoiceDate = new Date(invoiceData.basics.invoiceDateTime);
          const formattedInvoiceDate = [
            invoiceDate.getDate().toString().padStart(2, '0'),
            (invoiceDate.getMonth() + 1).toString().padStart(2, '0'),
            invoiceDate.getFullYear()
          ].join('-');

          setInvoiceDetails({
            invoiceNo: invoiceData.basics.invoiceNo,
            orderRef: invoiceData.basics.orderRef,
            platform: invoiceData.basics.platform,
            invoiceDate: formattedInvoiceDate,
            currency: invoiceData.basics.currency,
            customerName: invoiceData.customer.name,
            companyName: invoiceData.customer.companyName,
            companyNumber: invoiceData.customer.companyNumber,
            companyRegNumber: invoiceData.customer.companyRegNumber,
            vatNumber: invoiceData.customer.vatNumber,
            phoneNumber: invoiceData.customer.phone,
            emailAddress: invoiceData.customer.email,
            billingAddress: invoiceData.customer.billingAddress,
            shippingCost: invoiceData.shippingDetails.cost ? invoiceData.shippingDetails.cost.toString() : '0.00',
            shippingAddress: invoiceData.shippingDetails.address,
            shipper: invoiceData.shippingDetails.shipper,
            trackingNumber: invoiceData.shippingDetails.trackingNumber,
            taxPercentage: invoiceData.financials.taxPercentage ? invoiceData.financials.taxPercentage.toString() : '0',
            paidAmount: invoiceData.financials.paidAmount ? invoiceData.financials.paidAmount.toString() : '0.00',
            discount: invoiceData.financials.discount ? invoiceData.financials.discount.toString() : '0.00',
            taxAmount: invoiceData.financials.taxAmount ? invoiceData.financials.taxAmount.toFixed(2) : '0.00',
            totalAmount: invoiceData.financials.totalAmount ? invoiceData.financials.totalAmount.toFixed(2) : '0.00',
            balance: invoiceData.financials.balance ? invoiceData.financials.balance.toFixed(2) : '0.00',
            invoiceName: invoiceData.financials.invoiceName,
            invoiceNote: invoiceData.financials.invoiceNote
          });

          setRows(invoiceData.products.map(prod => ({
            product: prod.name,
            description: prod.description,
            quantity: prod.quantity ? prod.quantity.toString() : '0',
            unitPrice: prod.unitPrice ? prod.unitPrice.toFixed(2) : '0.00',
            totalPrice: prod.quantity && prod.unitPrice ? (prod.quantity * prod.unitPrice).toFixed(2) : '0.00'
          })));

          const userId = invoiceData.userId;
          fetchBusinessDetails(userId);

        } catch (error) {
          console.error("Failed to fetch invoice:", error);
          alert("Failed to load invoice details.");
        }
      }
    };

    fetchInvoiceData();
  }, [invoiceId]);

  const fetchBusinessDetails = async (userId) => {
    try {
      const response = await axiosInstance.get(`/businesses/${userId}`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch business data');
      }
      const businessData = await response.data;
      setBusinessDetails({
        invoiceTemplateDetails: {
          companyName: businessData.invoiceTemplateDetails.companyName,
          contactNumber: businessData.invoiceTemplateDetails.contactNumber,
          emailAddress: businessData.invoiceTemplateDetails.emailAddress,
          regNumber: businessData.invoiceTemplateDetails.regNumber,
          vatNumber: businessData.invoiceTemplateDetails.vatNumber,
          companyAddress: businessData.invoiceTemplateDetails.companyAddress,
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const totalProductsCost = rows.reduce((sum, row) => sum + parseFloat(row.totalPrice || 0), 0);
    const discount = parseFloat(invoiceDetails.discount || 0);
    const subTotal = (totalProductsCost-discount).toFixed(2);
    setInvoiceDetails(prevDetails => ({
      ...prevDetails,
      subTotal
    }));
  }, [rows, invoiceDetails.shippingCost, invoiceDetails.discount, invoiceDetails.paidAmount]);

  const [imageUri] = useState(localImage);
  const currencySign = currencySymbols[invoiceDetails.currency] || invoiceDetails.currency;

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>{invoiceDetails.invoiceName ? invoiceDetails.invoiceName.toUpperCase() : "INVOICE"}</Text>
          
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}><Text style={styles.tableCell}>Invoice No: {invoiceDetails.invoiceNo}</Text></View>
              <View style={styles.tableColHeader}><Text style={styles.tableCell}>Invoice Date: {invoiceDetails.invoiceDate}</Text></View>
              <View style={styles.tableColHeader}><Text style={styles.tableCell}>Order Ref: {invoiceDetails.orderRef}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW50}><Text style={styles.tableCell}>Bill to: {invoiceDetails.customerName}</Text></View>
              <View style={styles.tableW50}><Text style={styles.tableCell}>Contact No: {invoiceDetails.phoneNumber}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW99}><Text style={styles.tableCell}>Address: {invoiceDetails.billingAddress}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Company Name: {invoiceDetails.companyName}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Company Reg: {invoiceDetails.companyRegNumber}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>VAT No: {invoiceDetails.vatNumber}</Text></View>
            </View>
          </View>

          <View style={styles.table}>
            
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader30}><Text style={styles.tableCell}>Item </Text></View>
              <View style={styles.tableColHeader30}><Text style={styles.tableCell}>Description </Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>Qty </Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>Unit Price </Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>Total Price </Text></View>
            </View>
            {rows.map((row, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableW30}><Text style={styles.tableCell}>{row.product} </Text></View>
              <View style={styles.tableW30}><Text style={styles.tableCell}>{row.description} </Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{row.quantity}</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(row.unitPrice).toFixed(2)}</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(row.totalPrice).toFixed(2)}</Text></View>
            </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Discount</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(invoiceDetails.discount).toFixed(2)}</Text></View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Sub Total</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(invoiceDetails.subTotal).toFixed(2)}</Text></View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Tax ({invoiceDetails.taxPercentage}%)</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{invoiceDetails.taxAmount}</Text></View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Shipping Cost</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(invoiceDetails.shippingCost).toFixed(2)}</Text></View>
            </View>
            
            <View style={styles.tableRow}>
            <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Total Amount</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(invoiceDetails.totalAmount).toFixed(2)}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableW26, ...styles.alignCenter}}><Text style={styles.tableCell}>Paid Amount</Text></View>
              <View style={{...styles.tableW13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(invoiceDetails.paidAmount).toFixed(2)}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableW60}><Text style={styles.tableCell}> </Text></View>
              <View style={{...styles.tableColHeader26, ...styles.alignCenter}}><Text style={styles.tableCell}>Amount Due</Text></View>
              <View style={{...styles.tableColHeader13, ...styles.alignCenter}}><Text style={styles.tableCell}>{currencySign}{parseFloat(invoiceDetails.balance).toFixed(2)}</Text></View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={{...styles.tableColHeader99, ...styles.alignCenter}}><Text style={styles.tableCell}>Notes</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={{...styles.tableW33, ...styles.alignCenter}}><Text style={styles.tableCell}>{invoiceDetails.shipper}</Text></View>
              <View style={styles.tableW66}><Text style={styles.tableCell}>{invoiceDetails.trackingNumber}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={{...styles.tableW33, ...styles.alignCenter}}><Text style={styles.tableCell}>Delivery address</Text></View>
              <View style={styles.tableW66}><Text style={styles.tableCell}>{invoiceDetails.shippingAddress}</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={{...styles.tableW33, ...styles.alignCenter}}><Text style={styles.tableCell}>Invoice Note</Text></View>
              <View style={styles.tableW66}><Text style={styles.tableCell}>{invoiceDetails.invoiceNote}</Text></View>
            </View>
          </View>

          <View style={styles.footer}>
            <Text style={styles.content}>{businessDetails.invoiceTemplateDetails.companyName}</Text>
            <Text style={styles.content}>{businessDetails.invoiceTemplateDetails.companyAddress}</Text>
            <Text style={styles.content}>Company Reg. No: {businessDetails.invoiceTemplateDetails.regNumber} - VAT No: {businessDetails.invoiceTemplateDetails.vatNumber}</Text>
            <Text style={styles.content}>{businessDetails.invoiceTemplateDetails.emailAddress}</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PrintInvoice;