import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is correctly imported if you're using it
import '../css/login.css';
import '../css/home.css'
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';

export default function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({ email: ''});
    const [message, setMessage] = useState('');

    const validateForm = () => {
        let tempErrors = { email: '' };
        let formIsValid = true;

        // Simple email validation
        if (!email) {
        formIsValid = false;
        tempErrors['email'] = 'Email is required';
        }

        setErrors(tempErrors);
        return formIsValid;
    };

    //const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            sendResetPasswordLink();
        }
    };

    const sendResetPasswordLink = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/resetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    setMessage("Unfortunately, we were unable to find an account associated to this email address.");
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
            setMessage("Thanks for providing your email address. You will receive a password reset email if your email address is verified. If you don't receive an email, please check the junk folder.");
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    }

    return (
        <>
        <HomepageMenu />
        <Helmet>
            <title>Forgot Password</title>
        </Helmet>
        <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
      >
            <div className="text-center space-y-8 max-w-3xl mx-auto relative z-10 mt-5">
                <div className="login-card">
                    <h2 className=' fw-bold'>Forgot your password?</h2>
                    <p className='text-center text-dark'>Please enter your email address below</p>
                    <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className={`form-control p-4 ${errors.email ? 'is-invalid' : ''}`}
                        placeholder="Email"
                        autoFocus
                        />
                        <div className="invalid-feedback">{errors.email}</div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-dark w-100 p-3 bg-blue">Submit</button>
                    </div>
                    </form>
                    {message && <div className="alert alert-success mt-3 text-center text-dark">{message}</div>}
                    <hr></hr>
                    
                    <div className="d-flex justify-content-center">
                        <a href="/login" className="text-decoration-none btn btn-success">Return to login</a>
                    </div>
                </div>
            </div>
            <Footer />
            </div>
            
        </>
    )
}