import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import firstImage from '../images/services.png'
import users from '../images/services-users.png'
import integrations from '../images/services-integrations.png'
import apis from '../images/services-apis.png'
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEuroSign, faListCheck, faScrewdriverWrench, faToolbox, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  return (
    <>
    <HomepageMenu />
    <Helmet>
                <title>Services</title>
                <meta name="description" content="Discover how Flexmerce streamlines customer service automation and reduces marketplace fees on platforms like Backmarket and Refurbed. Our comprehensive solutions boost customer satisfaction, automate invoicing, and minimize customer service workload, empowering your business to grow effortlessly." />

            </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container services-main-container">
        <div className='row landing-container'>
          
          <div className='col-sm-6 landing-container-image services-first-image-div'>
            <img src={firstImage} alt='autopilot' className='services-first-image' />
          </div>
          <div className='col-sm-6 landing-container-text'>
            <h1 className='first-title-container font-orange'>Put your customer service on Autopilot</h1>
            <p className='mt-4 font-light home-services-p'>
              Efficiently streamline customer support, reduce response times, and deliver seamless experiences that keep customers satisfied and grow your business.
            </p>
            <a href='/registerBusiness'>
              <button className='btn-services-calculate-savings'>
              Start your 7-days free trial
              </button>
            </a>
            <a href='/calculatesavings'>
              <button className='btn-services-contact'>
              Predict your savings
              </button>
            </a>
          </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>How We Help Reduce Fees and Automate Processes</h1>
          <p className=' font-light home-services-p'>
          Discover how our automation solutions streamline invoicing, lower marketplace costs, and decrease customer service load on platforms like Backmarket and Refurbed.
          </p>

          <div className='row mt-3'>
            <div className='col'></div>
            <div className='col-sm-3 marketplace-tile m-2'>
              <div className="services-icon">
                <img src={bmlogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy'/>
              </div>
              <h4 className='text-900 font-orange mb-4'>Backmarket Integration</h4>
              <p className='home-services-p-1rem p-2'>Our integration with Backmarket has the potential to lower your marketplace fee percentage by reducing customer service ticket volume. With fewer issues raised, your business showcases professionalism and efficiency, building a positive reputation on Backmarket and opening doors for possible fee reductions. By automating invoicing, we minimize workload, reduce errors, ensure timely invoice delivery, and emphasize a customer-first approach.</p>
              <ul>
                <li className='home-services-p-1rem'>Get higher Backbox rates with improved insights</li>
                <li className='home-services-p-1rem'>Potential for reduced Backmarket fees by marketplace reputation</li>
                <li className='home-services-p-1rem'>Lower fees with fewer tickets, highlighting business proficiency</li>
                <li className='home-services-p-1rem'>Reduced customer service load and faster response times</li>
                <li className='home-services-p-1rem'>Automated invoice generation and reliable delivery</li>
              </ul>
            </div>
            <div className='col-sm-3 marketplace-tile m-2'>
            <div className="services-icon">
                <img src={rflogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Refurbed Integration</h4>
              <p className='home-services-p-1rem p-2'>Our Refurbed integration is designed to help reduce marketplace fees by streamlining support processes and minimizing ticket volume. By showcasing your business’s professionalism through automated invoicing, you contribute to Refurbed's reputation, increasing the potential for fee reductions over time. This integration also lowers operational costs, allowing your team to focus on high-priority tasks while we handle routine customer support needs.</p>
              <ul>
                <li className='home-services-p-1rem'>Get higher Buybox rates with improved CSTA</li>
                <li className='home-services-p-1rem'>Potential for reduced Refurbed fees by elevating reputation</li>
                <li className='home-services-p-1rem'>Faster, automated invoicing enhances business credibility</li>
                <li className='home-services-p-1rem'>Efficient service with fewer tickets, boosting customer satisfaction</li>
                <li className='home-services-p-1rem'>Lower fees by reducing support load and showcasing professionalism</li>
              </ul>
            </div>
            <div className='col'></div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/registerBusiness">
            <button className="btn-services-calculate-savings">Start your 7-days free trial</button>
          </a>
        </div>
        </div>

        <hr></hr>

        <div className='experience-container mt-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>Backed by experience and expertise</h1>
          <p className='font-light home-services-p'>
          Save thousands in marketplace fees with automations deployed by hundreds of businesses, freeing up hours for customer service teams and satisfying clients.
          </p>
          <div className='row mt-5'>
            <div className='col-sm-4'>
              <img src={users} className='experience-images' alt='users'/>
              <p className='p-4font-light home-services-p'><span className='font-orange'>50+</span><br></br>Users</p>
            </div>
            <div className='col-sm-4'>
              <img src={integrations} className='experience-images' alt='integrations'/>
              <p className='p-4font-light home-services-p'><span className='font-orange'>200+</span><br></br>Integrations</p>
            </div>
            <div className='col-sm-4'>
              <img src={apis} className='experience-images' alt='APIs'/>
              <p className='p-4font-light home-services-p'><span className='font-orange'>50,000+</span><br></br>Tickets</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }} className='mb-5'>
          <a href="/pricing">
            <button className="btn-services-calculate-savings">Explore our low fees</button>
          </a>
        </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h5 className='benefits-top-subtitle'>Save big numbers on marketplace fees</h5>
          <h1 className='mb-4 experience-title font-orange'>Key benefits and services</h1>
          <p className='font-light home-services-p'>
            Unlock the power of automation to streamline operations, reduce costs, and enhance customer satisfaction for scalable success.
          </p>

          <div className='row mt-3'>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faEuroSign} alt='Euro Sign' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Increase profit</h4>
              <p>Reduce marketplace fees by lowering number of customer service tickets, and drive profits through efficient operations.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faListCheck} alt='List Check' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Automate process</h4>
              <p>Automatically generate and deliver invoices, reducing tasks to focus on scaling your business effortlessly.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faClock} alt='Clock' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Save time</h4>
              <p>Free up your CS team to focus on critical areas to enhance your business' performance, eliminating repetitive tasks.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faScrewdriverWrench} alt='Troubleshooting' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Customer troubleshooting</h4>
              <p>Escalate customer support to us. We’ll work with your customer as needed to get to the bottom of an issue.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faToolbox}  alt='Maintenance' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Ongoing maintenance</h4>
              <p>Beyond release, any issues discovered are addressed quickly so your automations are not impacted.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faPhoneVolume} alt='Customer service support' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Dedicated support</h4>
              <p>Undivided attention is provided via dedicated communication channels and regular check-ins.</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/appointment">
            <button className="btn-services-calculate-savings">Book a free demo</button>
          </a>
        </div>
        </div>

        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}