import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import CheckAuth from '../hooks/checkAuth';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';
import 'bootstrap'
import '../css/dashboard.css';
import '../css/exportInvoices.css';

export default function ExportInvoices() {
    CheckAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [taxOptions, setTaxOptions] = useState([]);
    useEffect(() => {
        async function fetchTaxOptions() {
            const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
            if (userId) {
                setLoading(true);
                try {
                    const response = await axiosInstance.get(`/taxOption/${userId}`);
                    if (response.status !== 200) {
                        throw new Error('Failed to fetch Tax options');
                    }
                    const data = await response.data;
                    setTaxOptions(Array.isArray(data) ? data : []);
                    setLoading(false);
                } catch (error) {
                    console.error('Error:', error);
                    setLoading(false);
                }
            }
        }
        fetchTaxOptions();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        
        const fromDate = formData.get('fromDate');
        const toDate = formData.get('toDate');
        const platformId = formData.get('platformId'); // 0 means all platforms
        const taxOptionId = formData.get('taxOptionId'); // 0 means all tax options
        const userId = localStorage.getItem('userId');
    
        const queryString = `?fromDate=${fromDate}&toDate=${toDate}&platformId=${platformId}&taxOptionId=${taxOptionId}&userId=${userId}`;
        
        window.open(`/PrintMultipleInvoices${queryString}`, '_blank');
    };

    return (
        <div className='container mb-5'>
    <Helmet>
        <title>Export Invoices</title>
    </Helmet>
    {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
    )}
    <h2 className='mt-3 mb-4 fw-bold text-center'>Export invoices</h2>
    <div className='card border-radius-20'>
        <form onSubmit={handleSubmit}>

            <div className='row p-3'>
                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                        <input
                            type="date"
                            placeholder=""
                            className='form-control w-100'
                            name='fromDate'
                            required
                        />
                        <label className="floating-label-register">From date</label>            
                    </div> 
                </div>

                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                        <input
                            type="date"
                            placeholder=""
                            className='form-control w-100'
                            name='toDate'
                            required
                        />
                        <label className="floating-label-register">To date</label>            
                    </div> 
                </div>

                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                        <select
                            className="form-control"
                            name='platformId'
                            required
                        >
                            <option value="0">All</option>
                            <option value="1">Manual</option>
                            <option value="2">Back Market</option>
                            <option value="3">Refurbed</option>
                        </select>
                        <label className="floating-label-register">Platform</label>            
                    </div> 
                </div>

                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                        <select
                            className="form-control"
                            name="taxOptionId"
                            required
                        >
                            <option value="0">All</option>
                            {taxOptions
                                    .filter(taxOption => taxOption.active === 1)
                                    .map((taxOption, index) => (
                                        <option
                                            key={index}
                                            value={taxOption._id}
                                        >
                                            {taxOption.optionTitle}
                                        </option>
                            ))}
                        </select>
                        <label className="floating-label-register">Tax type</label>            
                    </div> 
                </div>

                <div className='d-flex justify-content-center'>
                    <input type="submit" className='btn bg-blue text-light w-50 p-2 mt-3 mb-3 rounded-md' value='Export invoices' />
                </div>
            </div>

        </form>
    </div>
</div>

    );
}