import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import firstImage from '../images/pricing.png'

import fivteen from '../images/15p.png'
import seventeen from '../images/17p.png'
import twenty from '../images/20p.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa7, faCircleCheck, faRepeat } from '@fortawesome/free-solid-svg-icons';

export default function Pricing() {
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  const faqs = [
    {
      question: "What happens after my 7-day free trial?",
      answer: "After your free trial, you’ll be moved to our pay-as-you-go model. You’ll only be billed for the invoices processed, based on the volume tier you fall into."
    },
    {
      question: "Can I change my invoice volume tier at any time?",
      answer: "Yes, our pricing adjusts automatically based on your monthly invoice volume. You’ll benefit from lower rates as your volume grows."
    },
    {
      question: "Is there any setup fee or hidden charges?",
      answer: "No, there are no setup fees, contracts, or hidden charges. You only pay for the invoices you process."
    },
    {
      question: "How do I cancel my subscription?",
      answer: "You can cancel your subscription at any time, and there’s no cancellation fee. Simply contact our support team or manage your subscription in your account settings."
    }
  ];

  return (
    <>
    <HomepageMenu />
    <Helmet>
      <title>Pricing</title>
      <meta name="description"
      content=
      "Explore Flexmerce's flexible and affordable pricing plans, designed to fit your invoicing needs. Start with a 7-day free trial and pay as low as 15p per invoice. Automate your invoicing process, reduce marketplace fees, and streamline your operations on platforms like Backmarket and Refurbed. Discover how Flexmerce can help you save time and reduce costs, no matter the size of your business."
      />
    </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container services-main-container">
        <div className='row landing-container'>
          <div className='col-sm-6 landing-container-text'>
            <h1 className='first-title-container font-orange mt-3'>Start Your 7-Day Free Trial</h1>
            <p className='mt-4 font-light home-services-p'>
            Experience the full power of Flexmerce with our 7-day free trial. No credit card required, no commitments. Simply sign up, explore the platform, and see how it can streamline your invoicing process and reduce marketplace fees on Backmarket, Refurbed, and more.
            </p>
            <a href='/calculatesavings'>
              <button className='btn-services-calculate-savings'>
                Predict your savings
              </button>
            </a>
            <a href='/appointment'>
              <button className='btn-services-contact'>
                Book a free demo
              </button>
            </a>
          </div>
          <div className='col-sm-6 landing-container-image pricing-first-image-div'>
            <img src={firstImage} alt='autopilot' className='pricing-first-image' />
          </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>Flexible Pricing to Match Your Growth</h1>
          <p className='font-light home-services-p'>
            We understand that each business is unique. That’s why our pricing adapts to your usage. Whether you're a small business or handling a high volume of transactions, Flexmerce scales with you to provide cost-effective invoicing automation.
          </p>

          <div className='row mt-3'>
            <div className='col-sm-3 services-tile m-2'>
              <img src={twenty} alt='backmarketLogo' className='marketplace-logo mb-3 mt-3' loading='lazy' />
              <h4 className='text-900 font-orange mb-4 mt-2'>1 - 999</h4>
              <h4 className='text-900 font-orange'>£0.20</h4>
              <p>per automated invoice</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <img src={seventeen} alt='backmarketLogo' className='marketplace-logo mb-3 mt-3' loading='lazy' />
              <h4 className='text-900 font-orange mb-4 mt-2'>1,000 - 2,000</h4>
              <h4 className='text-900 font-orange'>£0.17</h4>
              <p>per automated invoice</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <img src={fivteen} alt='backmarketLogo' className='marketplace-logo mb-3 mt-3' loading='lazy' />
              <h4 className='text-900 font-orange mb-4 mt-2'>2,000+</h4>
              <h4 className='text-900 font-orange'>£0.15</h4>
              <p>per automated invoice</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/registerbusiness">
            <button className="btn-services-calculate-savings">Start 7-days free trial</button>
          </a>
          </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>How It Works</h1>

          <div className='row mt-3'>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon mb-3">
                <FontAwesomeIcon icon={fa7} alt='number 7' />
              </div>
              <h4 className='text-900 font-orange mb-4'>7-Day Free Trial</h4>
              <p>Start with a full-featured free trial to experience the benefits firsthand.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon mb-3">
                <FontAwesomeIcon icon={faCircleCheck} alt='circle check' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Transparent Pricing</h4>
              <p>Pay for what you use, with rates decreasing as the invoice volume increases.</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon mb-3">
                <FontAwesomeIcon icon={faRepeat} alt='repeat' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Automated Invoicing</h4>
              <p>Every invoice is automated, giving you more time to focus on your business.</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/services">
            <button className="btn-services-calculate-savings">Explore our services</button>
          </a>
          </div>
        </div>

        <hr></hr>

        <div className="testimonial-section mt-3 mb-5">
          <h2 className="first-title-container font-orange text-center mt-5 mb-5">Why Choose Flexmerce?</h2>
            <div className="marketplace-tile services-why-choose-us-tile">
              <ul className='services-why-choose-us-ul'>
                <li>
                  <b className='font-orange'>Cost-Effective</b>: Our pricing structure lowers your cost per invoice as you grow, saving you money over time.
                </li>
                <li>
                  <b className='font-orange'>Efficient</b>: Automate the invoicing process and avoid the hassle of manual work, minimizing errors and increasing productivity.
                </li>
                <li>
                  <b className='font-orange'>Reduces Fees</b>: With fewer customer service tickets and timely invoicing, we can help you reduce marketplace fees on platforms like Backmarket and Refurbed.
                </li>
                <li>
                  <b className='font-orange'>Seamless Integration</b>: Connect Flexmerce to your marketplace accounts and start automating right away.
                </li>
                <li>
                  <b className='font-orange'>Secure and Reliable</b>: Your data is safe with us, backed by industry-leading security protocols.
                </li>
              </ul>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href="/calculatesavings">
                <button className="btn-services-calculate-savings">Calculate your savings</button>
              </a>
            </div>
        </div>

        <hr></hr>

        <div className="faq-section">
          <h2 className="first-title-container font-orange text-center mt-5 mb-5">FREQUENTLY ASKED QUESTIONS</h2>
          <div className="faq-grid mb-5">
            {faqs.map((faq, index) => (
              <div className="faq-flip-card" key={index}>
                <div className="faq-card-inner">
                  <div className="faq-card-front">
                    <h4 className="faq-question">{faq.question}</h4>
                  </div>
                  <div className="faq-card-back">
                    <p className="faq-answer">{faq.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div style={{ display: "flex", justifyContent: "center" }} className='mb-3'>
              <a href="/registerbusiness">
                <button className="btn-services-calculate-savings">Start 7-days free trial</button>
              </a>
            </div>
        </div>

        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}