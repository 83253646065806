import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register required elements and scales
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

const LineChart = ({ data, labels }) => {
    const chartData = {
        labels: labels, // Array of dates
        datasets: [
            {
                label: 'Invoices created',
                data: data, // Array of numbers
                fill: false,
                borderColor: '#ff8a00',
                tension: 0.25,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Allows the chart to fill the container
        scales: {
            y: {
                ticks: {
                    // Ensure only integer values are displayed
                    callback: function (value) {
                        return Number.isInteger(value) ? value : '';
                    },
                    stepSize: 1, // Force steps to increment by 1
                },
                beginAtZero: true, // Start axis at zero
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
    };

    return (
        <div style={{ height: '15rem', width: '100%' }}>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default LineChart;
