import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';

function CheckAuth( userType = 'user' ) {
    const { isAuthenticated, userRole } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isAuthenticated || userRole !== userType) {
            // Redirect based on the userType prop
            if (userType === 'admin') {
                navigate('/adminlogin');
            } else {
                navigate('/login');
            }
        }
    }, [isAuthenticated, userRole, userType, navigate]);

    return null;
}

export default CheckAuth;