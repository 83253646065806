import React from 'react';
import './App.css';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import NewInvoice from './pages/NewInvoice';
import Customers from './pages/Customers';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import PrintInvoice from './pages/PrintInvoice';
import Logout from './pages/logout';
import NoPage from './pages/NoPage';
import ResetPassword from './pages/ResetPassword';
import RegisterBusiness from './pages/RegisterBusiness';
import AdminLogin from './pages/AdminLogin';
import AddUpdateTaxOption from './pages/AddUpdateTaxOption';
import ManageTaxOptions from './pages/ManageTaxOptions';
import ExportInvoices from './pages/ExportInvoices';
import PrintMultipleInvoices from './pages/PrintMultipleInvoices';
import Services from './pages/Services';
import Appointment from './pages/Appointment';
import CalculateSavings from './pages/CalculateSavings';
import JobHistories from './pages/JobHistories';
import Pricing from './pages/Pricing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import SendCustomEmail from './pages/SendCustomEmail';
import EmailVerification from './pages/EmailVerification';
import Invoices from './pages/Invoices';

import { AuthProvider } from './context/AuthContext';
import Blog from './pages/Blog'; // Your dynamic blog page component
import BlogList from './pages/BlogList';


const App = () => {
  return (
    <AuthProvider> {/* Wrap everything inside AuthProvider */}
      <Router>
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invoice/new" element={<NewInvoice />} />
          <Route path="/invoice/edit/:invoiceId" element={<NewInvoice />} /> 
          <Route path="/customers" element={<Customers />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/printInvoice/:invoiceId" element={<PrintInvoice />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/verification/:token" element={<ResetPassword />} />
          <Route path="/registerbusiness" element={<RegisterBusiness />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/manageTaxOptions" element={<ManageTaxOptions />} />
          <Route path="/addUpdateTaxOption/new" element={<AddUpdateTaxOption />} />
          <Route path="/addUpdateTaxOption/edit/:taxOptionId" element={<AddUpdateTaxOption />} />
          <Route path="/exportInvoices" element={<ExportInvoices />} />
          <Route path="/printMultipleInvoices" element={<PrintMultipleInvoices />} />
          <Route path="/services" element={<Services />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/calculateSavings" element={<CalculateSavings />} />
          <Route path="/jobHistories" element={<JobHistories />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/verification" element={<EmailVerification />} />
          <Route path="/sendCustomEmail" element={<SendCustomEmail />} />
          <Route path="/invoices" element={<Invoices />} />

          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:slug" element={<Blog />} /> {/* Dynamic blog route */}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;