import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/login.css'
import 'bootstrap'

import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';

import firstImage from '../images/services-landing.png'
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'
import rklogo from '../images/rk-logo.png'
import fnlogo from '../images/fn-logo.png'
import cdlogo from '../images/cd-logo.png'
import sflogo from '../images/sf-logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFileZipper, faClock, faEuroSign, faListCheck, faScrewdriverWrench, faToolbox, faPhoneVolume, faRepeat, faFileShield, faHandHoldingDollar} from '@fortawesome/free-solid-svg-icons';


export default function SparkAILanding() {
  const [menuHeight, setMenuHeight] = useState(0);
  const images = [bmlogo, rflogo, rklogo, fnlogo, cdlogo, sflogo];
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  const faqs = [
    {
      question: "How can Flexmerce help improve my BackBox or BuyBox rates on marketplaces?",
      answer: "Automating invoicing with Flexmerce boosts your brand reputation and improves marketplace insight scores. This strengthens your standing, increasing your chances of receiving higher BuyBox or BackBox rates as marketplaces recognize your top-notch reputation."
    },
    {
      question: "How does Flexmerce help reduce marketplace fees on Backmarket and Refurbed?",
      answer: "Flexmerce automates your invoicing and customer service processes, resulting in fewer customer service tickets. This efficiency helps reduce the costs associated with marketplace fees by improving business reputation and customer satisfaction, which can lead to fee reductions over time."
    },
    {
      question: "Is my data secure with Flexmerce?",
      answer: "Absolutely. Flexmerce takes data security seriously. We implement robust security measures, including encrypted data storage and GDPR compliance, to ensure that all customer and transaction data remains safe and confidential."
    },
    {
      question: "Can Flexmerce really automate my invoicing completely?",
      answer: "Yes! Flexmerce offers full automation of your invoicing process. Once set up, invoices are generated and sent to your customers automatically, freeing up time for your team and reducing manual errors."
    },
    {
      question: "How will automating invoicing with Flexmerce impact my customer satisfaction?",
      answer: "Automated invoicing ensures customers receive their invoices promptly, improving their experience with your brand. With fewer delays and mistakes, your business demonstrates professionalism, leading to higher customer satisfaction and potentially lower marketplace fees."
    },
    {
      question: "Will Flexmerce affect my brand's reputation on marketplaces?",
      answer: "Yes, positively! By providing prompt and accurate invoicing, Flexmerce helps improve your brand’s reputation on marketplaces. This professional approach can lead to fewer support tickets and higher customer satisfaction, enhancing your standing with marketplaces like Backmarket and Refurbed."
    },
    // Add more FAQs as needed
  ];

  const testimonials = [
    {
      id: 1,
      text: "Flexmerce helped us significantly reduce our Backmarket fees. Their automation not only saved us money but also made invoicing a seamless experience!",
      customer: "Alex M.",
      company: "GadgetHub",
    },
    {
      id: 2,
      text: "With Flexmerce, we got better BackBox prices thanks to improved insights. It’s been a game-changer for us.",
      customer: "Sophia L.",
      company: "TechDeals",
    },
    {
      id: 3,
      text: "Flexmerce enhanced our brand reputation on Backmarket by automating our invoicing and improving service consistency.",
      customer: "Lucas P.",
      company: "ElectroMart",
    },
    {
      id: 4,
      text: "Since we implemented Flexmerce, our invoice requests decreased, allowing us to allocate more time to quality customer service.",
      customer: "Mia K.",
      company: "MobileFix",
    },
    {
      id: 5,
      text: "Flexmerce made invoice management easier for our accounting. It’s the best support we've had in managing our marketplace finances.",
      customer: "James H.",
      company: "SmartTech Solutions",
    },
  ];

  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide every 5 seconds
    return () => clearInterval(interval);
  }, []);
  return (
    <>
    <HomepageMenu />

    <Helmet>
      <title>Flexmerce - Automate Invoicing, Reduce Marketplace Fees, and Boost Customer Satisfaction</title>
      <meta name="description" content="Automate invoicing for marketplaces like Backmarket, Refurbed, Rakuten, and Fnac with our seamless solution. Instantly generate, upload, and send invoices to your customers, reducing manual work and errors. Improve efficiency, boost customer satisfaction, and streamline your order processing to focus on growing your e-commerce business." />

    </Helmet>
    <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container">
      <div className="row text-center">
        <div className="col-sm-6 landing-container-text">
          <div className="typewriter">
            <h1 className="gradient-text font-bold text-white">
              FLEXMERCE<span className="caret"></span>
            </h1>
          </div>
          <div className="mt-4">
            <h1 className="reveal-text">
              <span className="font-orange p-3">Your comprehensive portal for flexible commerce solutions</span>
            </h1>
          </div>
          <a href="/registerBusiness">
            <button className="btn-services-calculate-savings">Start your 7-days free trial</button>
          </a>
          <a href="/calculatesavings">
            <button className="btn-services-contact">Predict your savings</button>
          </a>
          <p className="mt-5">Automate your invoicing with Flexmerce and reduce marketplace fees on Back Market, Refurbed, and more.</p>
        </div>
        <div className="col-sm-6 mx-auto landing-container-image">
          <img src={firstImage} alt="autopilot" className="img-fluid" />
        </div>
      </div>


        <div className="w-100 relative z-10 d-flex align-content-center">
          <div className="absolute"></div>
            <div className="logo-row relative flex justify-between items-center max-w-4xl mx-auto px-4 py-8 pr-5 pl-5 pb-2  footerContainer">
              {images.map((image, index) => (
                <div key={index} className="w-30 h-30 flex items-center justify-center bg-white bg-opacity-10 rounded-full p-2 ml-2 platformIcon">
                  <div className="relative w-16 h-16">
                    <img src={image} alt={`Logo ${index + 1}`} className="object-contain w-full h-full" loading='lazy' />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <hr></hr>

        <h1 className='first-title-container font-orange text-center mt-5'>WHAT WE DO</h1>
        <p className='text-center pr-4 pl-4 home-services-p'>
          Automation solutions streamline invoicing, resulting in lower marketplace costs due to tickets raised, eligible for higher Backbox, and decrease customer service load on platforms like Backmarket and Refurbed.
        </p>

        <div className='row mt-3'>
            <div className='col'></div>
            <div className='col-sm-3 marketplace-tile m-2'>
              <div className="services-icon">
                <img src={bmlogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy'/>
              </div>
              <h4 className='text-900 font-orange mb-4'>Backmarket Integration</h4>
              <ul>
                <li>Get higher Backbox prices with improved insights</li>
                <li>Potential for reduced Backmarket fees by marketplace reputation</li>
                <li>Lower fees with fewer tickets, highlighting business proficiency</li>
                <li>Reduced customer service load and faster response times</li>
                <li>Automated invoice generation and reliable delivery</li>
              </ul>
            </div>
            <div className='col-sm-3 marketplace-tile m-2'>
            <div className="services-icon">
                <img src={rflogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy' />
              </div>
              <h4 className='text-900 font-orange mb-4'>Refurbed Integration</h4>
              <ul>
                <li>Get higher Buybox prices with improved CSTA</li>
                <li>Potential for reduced Refurbed fees by elevating reputation</li>
                <li>Faster, automated invoicing enhances business credibility</li>
                <li>Efficient service with less tickets, boosting customer satisfaction</li>
                <li>Lower fees by reducing support load and high professionalism</li>
              </ul>
            </div>
            <div className='col'></div>
        </div>
        
            
        <div className='row'>
          <div className='col-sm-2'>
            <a href='/services' className='text-center mb-3 w-100'>
              <button className='btn-services-calculate-savings'>
                Services
              </button>
            </a>
          </div>
          
          <div className='col-sm-3'>
            <a href='/blogs' className='text-center mb-3 w-100'>
              <button className='btn-services-calculate-savings'>
                Read our insights
              </button>
            </a>
          </div>
        </div>

        <hr></hr>

        <h1 className='first-title-container font-orange text-center mt-5 mb-3'>WHY CHOOSE FLEXMERCE</h1>

        <div className="home-services-container">
          <p className='text-center pr-4 pl-4 home-services-p'>At Flexmerce, we’re committed to helping businesses succeed on platforms like Backmarket and Refurbed. Our solutions are designed to streamline operations, reduce marketplace fees, and improve customer satisfaction. By automating invoice generation and securely handling data, we ensure that every transaction reflects professionalism and reliability. Our platform provides instant invoicing, efficient troubleshooting, and dedicated support to handle your customer service needs.</p>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faRepeat} alt="Repeat" />
            </div>
            <h4>Instant Invoicing</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faFileShield} alt="File Shield" />
            </div>
            <h4>Secure Data Handling</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faHandHoldingDollar} alt="Holding Dollar" />
            </div>
            <h4>Lower Fees</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faEuroSign} alt="Increased profit" />
            </div>
            <h4>Higher BackBox</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faListCheck} alt="Automate" />
            </div>
            <h4>Automate process</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faClock} alt="Clock" />
            </div>
            <h4>Save time</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faScrewdriverWrench} alt='Troubleshooting' />
              </div>
              <h4>Custom troubleshooting</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faToolbox}  alt='Maintenance' />
              </div>
              <h4>Ongoing maintenance</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faPhoneVolume} alt='Customer service support' />
              </div>
              <h4>Dedicated support</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faFileZipper} alt='File zipper' />
              </div>
              <h4>Export PDFs</h4>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/registerBusiness">
            <button className="btn-services-calculate-savings">Start your 7-days free trial</button>
          </a>
        </div>
        <p className='text-center pr-4 pl-4 home-services-p mt-5 mb-5'>With Flexmerce, you can save time, reduce operational costs, and focus on growing your business. Automated processes such as PDF export and invoicing mean fewer service tickets, which directly translates to reduced marketplace fees. Our tools are tailored to elevate your marketplace reputation, showcase your commitment to customer service, and maximize your profits. Join hundreds of businesses that trust Flexmerce to make their Backmarket and Refurbed operations more efficient, cost-effective, and successful.</p>
        

        <hr></hr>

        <div className="faq-section">
      <h2 className="first-title-container font-orange text-center mt-5 mb-5">FREQUENTLY ASKED QUESTIONS</h2>
      <div className="faq-grid mb-2">
        {faqs.map((faq, index) => (
          <div className="faq-flip-card" key={index}>
            <div className="faq-card-inner">
              <div className="faq-card-front">
                <h4 className="faq-question">{faq.question}</h4>
              </div>
              <div className="faq-card-back">
                <p className="faq-answer">{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
        
      </div>
      <div style={{ display: "flex", justifyContent: "center" }} className='mb-5'>
          <a href="/pricing">
            <button className="btn-services-calculate-savings">Explore our low prices</button>
          </a>
        </div>

      </div>

      <hr></hr>

      <div className="testimonial-section mt-3 mb-5">
        <h2 className="first-title-container font-orange text-center mt-5 mb-5">CUSTOMER TESTIMONIALS</h2>
        <div className="testimonial-tile">
          <div className="tile-content home-testimonial-tile">
            <p className="testimonial-text">"{testimonials[currentIndex].text}"</p>
            <h4 className="testimonial-company text-light">{testimonials[currentIndex].customer}</h4>
          </div>
        </div>
        <div className="testimonial-controls">
          <button className="prev-slide" onClick={prevSlide}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="testimonial-dots">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? "active" : ""}`}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
          <button className="next-slide" onClick={nextSlide}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

      <ContactSection />
      </div>
      <Footer/>
    </div>
    </>
  )
}