// BlogList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../css/login.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import blogs from '../context/BlogsData.js';

export default function BlogList() {
    const [menuHeight, setMenuHeight] = useState(0);
    useEffect(() => {
        const menuElement = document.querySelector('.navbar');
        if (menuElement) {
        setMenuHeight(menuElement.offsetHeight);
        }
    }, []);

    return (
        <>
    <HomepageMenu />
    <Helmet>
      <title>Flexmerce Blog - Tips to Reduce Marketplace Fees & Boost Customer Satisfaction</title>
      <meta name="description" content="Read the latest Flexmerce blog posts on fee reduction, automation, and customer support strategies for Backmarket, Refurbed, and more." />
    </Helmet>
    <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem" style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}>
        <div className="container mb-5">
            <h1 className='first-title-container font-orange text-center mt-5'>Flexmerce Insights</h1>
            <p className='mt-2 pt-3 mr-3 ml-3 font-light home-services-p  text-center'>
                Browse our collection of articles to learn how Flexmerce can transform your marketplace experience with cost-saving automation, reputation management, and more.
            </p>
            
            <div className='row mt-5'>
                {blogs.map((blog) => (
                    <div className='col-sm-6 marketplace-tile m-2 p-5 text-center'>
                    <span key={blog.id}>
                    <a className='blog-list-tile-link' href={`/blogs/${blog.slug}`}>
                        <h4 className='text-900 font-orange mb-4 blog-list-tile-title'>{blog.title}</h4>
                        <p className='blog-list-tile-subtitle'>{blog.description}</p>
                    </a>
                    </span>
                    </div> 
                ))}
            </div>

            <ContactSection />
        </div>
        <Footer />
    </div>
    </>
    )
}