import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';
import CheckAuth from '../hooks/checkAuth';
import * as CiIcons from 'react-icons/ci';
import { FaFileAlt } from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import 'bootstrap';
import '../css/dashboard.css';
import '../css/invoices.css';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import axiosInstance from '../components/axio';

export default function Invoices() {
    CheckAuth();
    const navigate = useNavigate();
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [orderRef, setOrderRef] = useState('');
    const [platformType, setPlatformType] = useState('2');
    const [loading, setLoading] = useState(false);

    // Filter States
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [invoiceType, setInvoiceType] = useState('All');

    // Pagination States
    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage, setInvoicesPerPage] = useState(10);

    async function FetchInvoices() {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/invoices/currentWeek/${localStorage.getItem('userId')}`);
            if (response.status !==200) {
                setLoading(false);
                throw new Error('Failed to fetch');
            }
            const data = await response.data;
            setFilteredInvoices(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }        
    }

    useEffect(() => {
        const fetchInvoices = async () => {
            FetchInvoices();
        };
        fetchInvoices();
    }, []);

    async function getSingleInvoice() {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/getSingleInvoice/${localStorage.getItem('userId')}/${orderRef}/${platformType}`);
            if (response.status !== 200) {
                setLoading(false);
                if (response.status === 404 || response.status === 500) {
                    alert('Failed to fetch invoices.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                setLoading(false);
                setOrderRef("");
                setPlatformType("2");
                alert(response.data.message);
                FetchInvoices();
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        } 
    }
    
    const handleEditClick = (invoiceId) => {
        navigate(`/invoice/edit/${invoiceId}`);
    };

    const handlePrintClick = (invoiceId) => {
        window.open(`/printInvoice/${invoiceId}`, '_blank');
    };

    const handleEmailClick = async (invoiceId) => {
        try {
            const response = await axiosInstance.get(`/sendInvoiceEmail/${invoiceId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status !== 200) {
                if (response.status === 404 || response.status === 401) {
                    alert('Email failed to send. Invoice not found or not authorized.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                alert('Email sent successfully!');
                FetchInvoices();
            }
        } catch (error) {
            console.error('Failed to send email:', error);
            alert('Failed to send email. Please try again.');
        }
    };

    async function FetchFilteredInvoices(query, startDate, endDate, invoiceType) {
        try {
            const response = await axiosInstance.get(`/invoices/filtered/${localStorage.getItem('userId')}?query=${query}&startDate=${startDate}&endDate=${endDate}&invoiceType=${invoiceType}`);

            if (response.status !== 200) {
                throw new Error('Failed to fetch');
            }
            const data = await response.data;
            setFilteredInvoices(data);
        } catch (error) {
            console.error('Error:', error);
        }        
    }

    // Filter Function
    useEffect(() => {
        const platformNumber = platformReverseMapping[invoiceType];
    
        const fetchFilteredInvoices = debounce(() => {
            // Check if any filter is applied
            if (searchQuery || startDate || endDate || invoiceType !== 'All') {
                FetchFilteredInvoices(searchQuery, startDate, endDate, platformNumber).then(() => {
                    setCurrentPage(1);
                });
            } else {
                // If no filters are applied, fetch current month's invoices
                FetchInvoices();
            }
        }, 1000); // Delay of 1 second
    
        fetchFilteredInvoices();
    
        return () => {
            // Cancel the debounced function on cleanup
            fetchFilteredInvoices.cancel();
        };
    }, [searchQuery, startDate, endDate, invoiceType]);
    

    const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);
    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleInvoicesPerPageChange = (e) => {
        setInvoicesPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const platformMapping = {
        0: 'All',
        1: 'Manual',
        2: 'Back Market',
        3: 'Refurbed'
    };

    const platformReverseMapping = Object.fromEntries(
        Object.entries(platformMapping).map(([key, value]) => [value, Number(key)])
    );

    const currencySymbols = {
        EUR: '€',
        GBP: '£',
        USD: '$',
        SEK: 'SEK',
        DKK: 'DKK'
    };
    

    return (
        
        <div className='container'>
            <Helmet>
                <title>Invoices</title>
            </Helmet>
            <h2 className='mt-3 fw-bold'>Invoices</h2>

            

            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}

            

            <div className='row mt-5 mb-1'>
                <div className='col-sm-9'>
                    <div className='bg-light text-dark text-center mb-5 messageBox p-3 pb-5'>
                        <h5>Easily create an invoice from marketplace</h5>
                        <hr></hr>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div className="form-group m-1 p-1">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={orderRef}
                                        onChange={(e) => setOrderRef(e.target.value)}
                                    />
                                    <label className="floating-label-register">Order ref.</label>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className="form-group m-1 p-1">
                                    <select
                                        className="form-control"
                                        value={platformType}
                                        onChange={(e) => setPlatformType(e.target.value)}
                                    >
                                        <option value="2">Back Market</option>
                                        <option value="3">Refurbed</option>
                                    </select>
                                    <label className="floating-label-register">Platform</label>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                            <button className='btn bg-blue text-light mt-2 w-100 p-2' onClick={getSingleInvoice}>Get invoice</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='bg-light text-dark text-center mb-5 messageBox p-3 pb-5'>
                        <h5>Create a manual invoice</h5>
                        <hr></hr>
                        <a href='/invoice/new' className='btn bg-blue text-light p-3'>New invoice</a>
                    </div>
                </div>
            </div>  

            <div className='bg-light text-dark text-center mb-5 messageBox p-3'>
                <div className="filter-section my-4">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 mb-3">
                            <div className="form-group m-1 p-1">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    style={{ border: '1px solid #1b2033' }}
                                />
                                <label className="floating-label-register">Search invoices</label>
                            </div> 
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                            <div className="form-group m-1 p-1">
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    style={{ border: '1px solid #1b2033' }}
                                />
                                <label className="floating-label-register">Start date</label>
                            </div> 
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                            <div className="form-group m-1 p-1">
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    style={{ border: '1px solid #1b2033' }}
                                />
                                <label className="floating-label-register">End date</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-2 mb-3">
                            <div className="form-group m-1 p-1">
                                <select
                                    className="form-control select-options"
                                    value={invoiceType}
                                    onChange={(e) => setInvoiceType(e.target.value)}
                                    style={{ border: '1px solid #1b2033' }}
                                >
                                    <option value="All">All</option>
                                    <option value="Manual">Manual</option>
                                    <option value="Back Market">Back Market</option>
                                    <option value="Refurbed">Refurbed</option>
                                </select>
                                <label className="floating-label-register">Platform</label>
                            </div>
                        </div>
                    </div>
                </div>
            


            <div className="pagination-container">
                <div>
                    <label htmlFor="invoicesPerPage" className="invoices-dropdown">Invoices per page:</label>
                    <select id="invoicesPerPage" value={invoicesPerPage} onChange={handleInvoicesPerPageChange}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div>
                    <button disabled={currentPage === 1} onClick={handlePreviousPage}>Previous</button>
                    <span className="mx-2">Page {currentPage} of {totalPages}</span>
                    <button disabled={currentPage === totalPages} onClick={handleNextPage}>Next</button>
                </div>
            </div>

            <table className='table-custom-radius text-center mt-3 mb-3 bg-transparent' id="main-table">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }} className='text-light bg-blue'>Date</th>
                        <th style={{ width: '10%' }} className='text-light bg-blue'>Invoice no</th>
                        <th style={{ width: '15%' }} className='text-light bg-blue'>Platform</th>
                        <th style={{ width: '10%' }} className='text-light bg-blue'>Order ref</th>
                        <th style={{ width: '25%' }} className='text-light bg-blue'>Customer name</th>
                        <th style={{ width: '5%' }} className='text-light bg-blue'>Qty</th>
                        <th style={{ width: '10%' }} className='text-light bg-blue'>Total amount</th>
                        <th style={{ width: '15%' }} className='text-light bg-blue'>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {currentInvoices.map((invoice, index) => {
                        return (
                        <tr key={index}>
                            <td data-label="Date">{new Date(invoice.basics.invoiceDateTime).toLocaleDateString()}</td>
                            <td data-label="Invoice no">{invoice.basics.invoiceNo}</td>
                            <td data-label="Platform">{platformMapping[invoice.basics.platform]}</td>
                            <td data-label="Order ref">{invoice.basics.orderRef}</td>
                            <td data-label="Customer name">{invoice.customer.name}</td>
                            <td data-label="Qty">{invoice.products.reduce((total, product) => total + product.quantity, 0)}</td>
                            <td data-label="Total amount">{`${currencySymbols[invoice.basics.currency] || invoice.basics.currency}${(invoice.financials.totalAmount ? invoice.financials.totalAmount.toFixed(2) : "0.00")}`}</td>
                            <td data-label="Options">
                                <div className="button-group">
                                    <button onClick={() => handleEditClick(invoice.basics.invoiceNo)} style={{ border: 'none', background: 'none' }}>
                                        <CiIcons.CiEdit style={{ height: '30px', width: '30px' }} />
                                    </button>
                                    <button onClick={() => handlePrintClick(invoice.basics.invoiceNo)} style={{ border: 'none', background: 'none' }}>
                                        <FaFileAlt style={{ height: '23px', width: '30px' }} />
                                    </button>
                                    {invoice.customer.email && (
                                        <button onClick={() => handleEmailClick(invoice.basics.invoiceNo)} style={{ border: 'none', background: 'none' }}>
                                            <SiMinutemailer style={{ height: '30px', width: '25px' }} />
                                        </button>
                                    )}
                                </div>
                            </td>

                        </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination-container mb-5">
                <div>
                </div>
                <div>
                    <button disabled={currentPage === 1} onClick={handlePreviousPage}>Previous</button>
                    <span className="mx-2">Page {currentPage} of {totalPages}</span>
                    <button disabled={currentPage === totalPages} onClick={handleNextPage}>Next</button>
                </div>
            </div>

            </div>

        </div>
    );
}