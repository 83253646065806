import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CheckAuth from '../hooks/checkAuth';
import { FaBox } from "react-icons/fa";
import { BsFillBoxFill } from "react-icons/bs";
import { BsBoxSeamFill } from "react-icons/bs";
import 'bootstrap';
import '../css/dashboard.css';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import axiosInstance from '../components/axio';

import LineChart from '../components/LineChart';
import DoughnutChart from '../components/DoughnutChart';

const DashboardTiles = ({ platformName, invoices, platformColor, icon, link, text }) => {
    return (
        <div className="tile" style={{ backgroundColor: platformColor }}>
            <div className="tile-platform">{platformName}</div>
            <div className="tile-invoices">{invoices}</div>
            <div className="tile-icon">{icon}</div>
            <div className="tile-link">{link}</div>
            <span className="tile-extra-info">{text}</span>
        </div>
    );
};

export default function Dashboard() {
    CheckAuth();
    const navigate = useNavigate();
    const [numOfInvoices, setNumOfInvoices] = useState(0);
    const [loading, setLoading] = useState(false);


    const [labels, setLabels] = useState([]); // For chart labels (days of the month)
    const [data, setData] = useState([]);

    const [platformCounts, setPlatformCounts] = useState({
        1: 0, // Manual
        2: 0, // Back Market
        3: 0  // Refurbed
    });

        async function FetchInvoices() {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/invoices/${localStorage.getItem('userId')}`);
                if (response.status !== 200) {
                    setLoading(false);
                    throw new Error('Failed to fetch');
                }
                const data = await response.data;
        
                // Group invoices by day
                const invoicesByDay = data.reduce((acc, invoice) => {
                    const creationDate = invoice?.basics?.creationDateTime; // Safely access creationDateTime
                    if (creationDate) {
                        const date = new Date(creationDate).toISOString().split('T')[0]; // Ensure valid date
                        acc[date] = (acc[date] || 0) + 1;
                    }
                    return acc;
                }, {});
        
                // Generate labels and data arrays
                const labels = [];
                const dataForChart = [];
                const now = new Date();
                const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        
                for (let day = 1; day <= daysInMonth; day++) {
                    const date = new Date(now.getFullYear(), now.getMonth(), day)
                        .toISOString()
                        .split('T')[0];
                    labels.push(day.toString().padStart(2, '0')); // Add day label
                    dataForChart.push(invoicesByDay[date] || 0); // Use 0 if no invoices on that day
                }
        
                setNumOfInvoices(data.length); // Total invoices
                setLabels(labels); // Update chart labels
                setData(dataForChart); // Update chart data
        
                const platformCounts = data.reduce((acc, invoice) => {
                    const platform = invoice.basics.platform;
                    acc[platform] = (acc[platform] || 0) + 1;
                    return acc;
                }, {});
                setPlatformCounts(platformCounts);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error:', error);
            }
        }
        

    useEffect(() => {
        const fetchInvoices = async () => {
            FetchInvoices();
        };
        fetchInvoices();
    }, []);

    const handleNewInvoiceClick = () => {
        navigate(`/invoice/new`);
    };

    const handleBackMarketFetchInvoices = async () => {
        setLoading(true);
        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort(); // Aborts the fetch request after a certain time
        }, 1200000); // Timeout set to 120 seconds
    
        try {
            const userId = localStorage.getItem('userId');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/backmarket/automateInvoicing/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: controller.signal, // Connect the controller to the fetch request
            });
    
            clearTimeout(timeout); // Clear the timeout if the request completes on time
    
            if (!response.ok && response.status !== 504) {
                const result = await response.json();
                setLoading(false);
                if (response.status === 404 || response.status === 500) {
                    alert('Failed to fetch invoices.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                setLoading(false);
                const result = await response.json();
                alert(result.message);
                FetchInvoices();
            }
        } catch (error) {
            setLoading(false);
    
            if (error.name === 'AbortError') {
                alert('Request timed out. Please try again.');
            } else {
                console.error('Failed to fetch invoices:', error);
                alert('Failed to fetch invoices. Please try again.');
            }
        }
    };

    const handleRefurbishedFetchInvoices = async () => {
        setLoading(true);
        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort(); // Aborts the fetch request after a certain time
        }, 1200000); // Timeout set to 120 seconds
        try {
            const userId = localStorage.getItem('userId');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/refurbished/automateInvoicing/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: controller.signal, // Connect the controller to the fetch request
            });

            clearTimeout(timeout); // Clear the timeout if the request completes on time

            if (!response.ok) {
                setLoading(false);
                if (response.status === 404 || response.status === 500) {
                    alert('Failed to fetch inovices.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                const result = await response.json();
                alert(result.message);
                FetchInvoices();
            }
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch inovices:', error);
            alert('Failed to fetch inovices. Please try again.');
        }
    };

    const platforms = [
        { name: 'Back Market', invoices: platformCounts[2], color: '#F5A623', icon: <FaBox />, text: 'Get invoices from Back Market' },
        { name: 'Refurbed', invoices: platformCounts[3], color: '#4A90E2', icon: <BsBoxSeamFill />, text: 'Get invoices from Refurbed' },
        { name: 'Manual', invoices: platformCounts[1], color: '#1b2033', icon: <BsFillBoxFill />, text: 'Create an invoice' }
    ];

    const platformLabels = ['Back Market', 'Refurbed', 'Manual'];
    const platformData = [
        platformCounts[2] || 0, // Back Market invoices count
        platformCounts[3] || 0, // Refurbed invoices count
        platformCounts[1] || 0, // Manual invoices count
    ];
    

    return (
        
        <div className='container'>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <h2 className='mt-3 mb-4 fw-bold'>Welcome {localStorage.getItem('businessName')}</h2>
            <div className='bg-light text-dark text-center mb-2 messageBox'>
                <p className='p-3'>
                    {numOfInvoices > 0 ? (
                        <>You have created <b>{numOfInvoices}</b> invoices this month. Well done!</>
                    ) : (
                        <>You haven't created any invoices this month.</>
                    )}
                </p>
            </div>

            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}

            <div className='row mt-2 mb-4'>
                {platforms.map((platform, index) => (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-1 dashboard-tile" key={index}>
                        <a
                            key={index}
                            href="#"
                            onClick={(e) => {
                            e.preventDefault();
                            if (platform.name === 'Back Market') {
                                handleBackMarketFetchInvoices();
                            } else if (platform.name === 'Refurbed') {
                                handleRefurbishedFetchInvoices();
                            } else if (platform.name === 'Manual') {
                                handleNewInvoiceClick();
                            }
                            }}
                            style={{ textDecoration: 'none' }}
                        >
                            <DashboardTiles
                            platformName={platform.name}
                            invoices={platform.invoices}
                            platformColor={platform.color}
                            icon={platform.icon}
                            link={platform.link}
                            text={platform.text}
                            />  
                        </a> 
                </div>    
                ))}
            </div>

            <div className='row mb-5 pb-5'>
                <div className='col-sm-8'>
                    <div className="chart-container">
                        <h5 className='text-center mb-3'>Invoices created per day</h5>
                        <hr></hr>
                        <LineChart labels={labels} data={data} className='chart-graph' />
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="chart-container doughnut">
                        <h5 className="text-center mb-3">Invoices per platform</h5>
                        <hr></hr>
                        <DoughnutChart labels={platformLabels} data={platformData} />
                    </div>
                </div>
            </div>
        </div>
    );
}