import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/appointment.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import { InlineWidget } from "react-calendly";

export default function Appointment() {
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  return (
    <>
    <HomepageMenu />
    <Helmet>
      <title>Book a demo</title>
      <meta name="description" content="Schedule a personalized demo with Flexmerce to explore how our solutions can automate your customer service, reduce marketplace fees, and streamline operations. Book a demo appointment today to see our platform in action and discover the benefits for your business." />
    </Helmet>
    <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
    >
      <div className='container'>
        <div className='row mt-5'>
          <div className='col'></div>
            <div className='col-sm-11 appointmentContainer'>
              <div className=''>
                <h1 className='mt-3 font-orange text-center appointment-title'>Book a demo appointment</h1>
                <InlineWidget url="https://calendly.com/flexmerce-info" className='calendar' />
              </div>
            </div>
          <div className='col'></div>
        </div>
        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}