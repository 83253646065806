import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ labels, data }) => {
    const chartData = {
        labels: labels, // E.g., ['Back Market', 'Refurbed', 'Manual']
        datasets: [
            {
                label: 'Invoices per Platform',
                data: data, // E.g., [10, 20, 30]
                backgroundColor: ['#F5A623', '#4A90E2', '#1b2033'], // Colors for each segment
                borderColor: ['#F5A623', '#4A90E2', '#1b2033'], // Matching border colors
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
    };

    return (
        <div style={{
            height: '15rem',
            width: '22rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Doughnut
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false, // Allow the chart to fill the container
                }}
            />
        </div>
    );
};

export default DoughnutChart;
