import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap'
import '../css/dashboard.css';
import CheckAuth from '../hooks/checkAuth';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';

export default function JobHistories() {
    CheckAuth();
    const navigate = useNavigate();
    const [jobHistories, setJobHistories] = useState([]);  // Initialize as an array
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetchJobHistories() {
            setLoading(true);
            const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
            if (userId) {
                try {
                    const response = await axiosInstance.get(`/jobOption/history/${userId}`);
                    if (response.status!==200) {
                        throw new Error('Failed to fetch Tax options');
                    }
                    const data = await response.data;
                    setJobHistories(Array.isArray(data) ? data : []);
                    setLoading(false);
                } catch (error) {
                    console.error('Error:', error);
                    setLoading(false);
                }
            }
        }
        fetchJobHistories();
    }, []);

    const platformMapping = {
        2: 'Back Market',
        3: 'Refurbed'
    };

    return (
        <div className='container mb-5'>
            <Helmet>
                <title>Job Histories</title>
            </Helmet>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <h2 className='mt-3 fw-bold'>Job Histories</h2>

            <h5 className='mt-4 fw-bold text-center'>Schedule</h5>
            <div className=''>
                <table className='mt-1 table table-custom-radius text-center bg-transparent' id="main-table">
                    <thead>
                        <tr>
                            <th className='text-light bg-blue'>Platform</th>
                            <th className='text-light bg-blue'>Monday - Friday</th>
                            <th className='text-light bg-blue'>Saturday - Sunday</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="Platform">Back Market</td>
                            <td data-label="Monday - Friday">
                                00:00 - 08:59 - Every 6 hours <br></br>
                                09:00 - 17:59 - Every 2 hours <br></br>
                                18:00 - 23:59 - Every 6 hours <br></br>
                            </td>
                            <td data-label="Saturday - Sunday">
                                00:00 - 23:59 - Every 6 hours <br></br>
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Date Time">Refurbed</td>
                            <td data-label="Monday - Friday">
                                00:00 - 09:59 - Every 6 hours <br></br>
                                10:00 - 18:59 - Every 2 hours <br></br>
                                19:00 - 23:59 - Every 6 hours <br></br>
                            </td>
                            <td data-label="Saturday - Sunday">
                                00:00 - 23:59 - Every 6 hours <br></br>
                            </td>
                        </tr>         
                    </tbody>
                </table>
            </div>



            <h5 className='mt-4 fw-bold text-center'>Last 7 days histories</h5>
            <div className=''>
                <table className='mt-1 table table-custom-radius text-center bg-transparent' id="main-table">
                    <thead>
                        <tr>
                            <th className='text-light bg-blue'>Date Time</th>
                            <th className='text-light bg-blue'>Platform</th>
                            <th className='text-light bg-blue'>No. of Invoices</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobHistories.map((jobHistory, index) => {
                            return (
                                <tr key={index}>
                                    <td data-label="Date Time">{new Date(jobHistory.dateTime).toLocaleString('en-GB', { 
                                        day: '2-digit', 
                                        month: '2-digit', 
                                        year: 'numeric', 
                                        hour: '2-digit', 
                                        minute: '2-digit', 
                                        second: '2-digit', 
                                        hour12: false 
                                    })}</td>
                                    <td data-label="Platform">{platformMapping[jobHistory.platformId]}</td>
                                    <td data-label="No. of invoices">{jobHistory.numberOfInvoices}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    )
}